import '../styles/global.css';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import React, { useEffect } from 'react';

import { PageLayout } from '../layouts/PageLayout';
import * as gtag from '../utils/gtag';

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: URL) => {
            gtag.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <PageLayout>
                <DefaultSeo
                    description={
                        'Javascript, PHP, Rust, Visualização de Dados e muito mais! Aprenda ou aprimore seus conhecimentos com Video tutoriais e posts sobre programação'
                    }
                    openGraph={{
                        type: 'website',
                        locale: 'pt-BR',
                        url: 'https://devsbrasil.com/',
                        site_name: 'DevsBrasil'
                    }}
                    twitter={{
                        handle: '@devsbrasil',
                        site: '@site',
                        cardType: 'summary_large_image'
                    }}
                />
                <Component {...pageProps} />
            </PageLayout>
        </>
    );
};
export default App;
