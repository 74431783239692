import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import * as React from 'react';
import { useEffect, useState } from 'react';

import categoriesName from '../categories.json';
import { ArrowIcon } from '../components/ArrowIcon/lazy';
import { Busca } from '../components/Busca/lazy';
import { MenuIcon } from '../components/MenuIcon/lazy';

const Github = dynamic(import('../public/images/gh.svg')) as typeof React.Component;
const Twitter = dynamic(import('../public/images/tw.svg')) as typeof React.Component;
const Youtube = dynamic(import('../public/images/yt.svg')) as typeof React.Component;
const Logo = dynamic(import('../public/images/devs.svg')) as typeof React.Component;

const { serverRuntimeConfig } = getConfig();

const __html = `
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag(){window.dataLayer.push(arguments);}
    
    gtag('js', new Date());
    gtag('config', '${serverRuntimeConfig.UA_TRACKING_ID}', { 
        page_path: window.location.pathname,
    });
`;

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();
    const [showMenu, setShowMenu] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        const handleRouteChange = () => {
            setShowMenu(false);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!showMenu) {
            setShowCategories(false);
        }
    }, [showMenu, setShowCategories]);

    return (
        <>
            <>
                <Script
                    strategy="worker"
                    src={`https://www.googletagmanager.com/gtag/js?id=${serverRuntimeConfig.UA_TRACKING_ID}`}
                />
                <Head>
                    <title>DevsBrasil</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <script type="text/partytown" dangerouslySetInnerHTML={{ __html }} />
                </Head>
            </>

            <main>
                <div
                    className={`bg-gradient-to-b from-blue-lightest to-white mb-6 md:mb-8 ${
                        (scrollPosition || showMenu) && 'shadow-lg'
                    } lg:shadow-none sticky top-0 z-20 lg:static`}>
                    <section className={'page-container max-w-screen-xl'}>
                        <header
                            className={
                                ' py-4 items-center relative sm:flex sm:grid-cols-none grid grid-cols-1'
                            }>
                            <Link href="/">
                                <a data-label="Site logo" className={'w-24 md:w-28 block'}>
                                    <span className="sr-only">devsbrasil.com</span>
                                    <Logo />
                                </a>
                            </Link>
                            <button
                                onClick={() => setShowMenu((v) => !v)}
                                className={`absolute top-8 right-0 inline-block sm:hidden w-8 h-8 bg-gray-200 ${
                                    showMenu ? 'text-blue-light' : 'text-gray-main'
                                } inline-hover hover:text-blue-light p-1`}
                                aria-label="Menu">
                                <MenuIcon showMenu={showMenu} />
                            </button>
                            <div
                                className={`${
                                    showMenu ? 'flex' : 'hidden'
                                } col-span-2 flex-col  sm:flex sm:flex-row mt-6 sm:mt-0 flex-1`}>
                                <nav className={`sm:flex flex-1  sm:justify-start justify-center`}>
                                    <ul className={`sm:ml-4 flex sm:flex-row flex-col`}>
                                        <li
                                            className={
                                                'mx-1 md:mx-2 lg:mx-3 py-2 sm:py-0 sm:border-0 border-b border-gray-light'
                                            }>
                                            <Link href="/">
                                                <a
                                                    className={`flex text-blue-dark p-2 font-semibold hover:text-blue-light active:text-blue-light focus:text-blue-light inline-hover`}>
                                                    Home
                                                </a>
                                            </Link>
                                        </li>
                                        <li
                                            onMouseLeave={() => setShowCategories(false)}
                                            className={
                                                'group mx-1 md:mx-2 lg:mx-3 py-2 sm:py-0 sm:border-0 border-b border-gray-light'
                                            }>
                                            <button
                                                onClick={() => setShowCategories((v) => !v)}
                                                className={` w-full cursor-pointer items-center flex p-2 font-semibold group-hover:text-blue-light group-active:text-blue-light group-focus:text-blue-light inline-hover justify-between sm:justify-start ${
                                                    showCategories
                                                        ? 'text-blue-light'
                                                        : 'text-blue-dark'
                                                }`}>
                                                Categorias
                                                <ArrowIcon rotate={showCategories} />
                                            </button>
                                            <ul
                                                className={`transform scale-0 group-hover:scale-100 ${
                                                    showCategories
                                                        ? 'scale-100 static visible'
                                                        : 'opacity-0 absolute invisible'
                                                } sm:absolute sm:opacity-100 sm:bg-white top-16 mb-4 sm:py-3 px-2 z-20 sm:shadow-lg sm:rounded-md`}>
                                                {Object.values(categoriesName).map(
                                                    ({ label, slug }) => (
                                                        <li key={slug} className={'mx-2 my-2'}>
                                                            <Link href={`/categoria/${slug}`}>
                                                                <a
                                                                    className={`text-blue-dark hover:text-blue-light inline-hover`}>
                                                                    ‣ {label}
                                                                </a>
                                                            </Link>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </li>
                                        <li
                                            className={
                                                'hidden mx-1 md:mx-2 lg:mx-3 py-2 sm:py-0 sm:border-0 border-b border-gray-light'
                                            }>
                                            <Link href="/">
                                                <a
                                                    className={`flex text-blue-dark p-2 font-semibold hover:text-blue-light active:text-blue-light focus:text-blue-light inline-hover`}
                                                    href={'mailto:contato@devsbrasil.com'}>
                                                    Contato
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                                <nav
                                    className={
                                        ' sm:pt-0 pt-4 flex-1 justify-center sm:justify-end flex items-center'
                                    }>
                                    <div className={'flex-1 lg:flex-none'}>
                                        <Busca />
                                    </div>
                                    <ul className={'flex ml-4'}>
                                        <li className={'flex'}>
                                            <a
                                                className={`px-2 text-gray-main hover:text-blue-light inline-hover`}
                                                target="_blank"
                                                rel="noreferrer"
                                                data-label="Youtube"
                                                href={'https://youtube.com/DevsBrasil'}>
                                                <Youtube className="h-6 w-6" />
                                            </a>
                                        </li>
                                        <li className={'flex'}>
                                            <a
                                                className={`px-2 text-gray-main hover:text-blue-light inline-hover`}
                                                target="_blank"
                                                rel="noreferrer"
                                                data-label="Twitter"
                                                href={'https://twitter.com/DevsBrasil'}>
                                                <Twitter className="h-6 w-6" />
                                            </a>
                                        </li>
                                        <li className={'flex'}>
                                            <a
                                                className={`px-2 text-gray-main hover:text-blue-light inline-hover`}
                                                target="_blank"
                                                rel="noreferrer"
                                                data-label="Github"
                                                href={'https://github.com/DevsBrasil'}>
                                                <Github className="h-6 w-6" />
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </header>
                    </section>
                </div>
                <div>{children}</div>
            </main>
        </>
    );
};
